<template> 
    <div class="media-article">
        <div class="title" v-if="full"><span>{{ article?.title }}</span><span class="icon link" @click="save"></span></div>
        <h2 v-else>{{ article?.title }}</h2>
        <h3 v-if="article?.date">{{ date() }}</h3>        
        <div class="media-content" ref="content">
            <div v-if="article?.cover" class="cover" :class="{ 'cover-full' : full, 'cover-mini': !full }"><img :src="article?.cover"></div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
moment.locale('ru');

export default {
    props: {
        article: {
            type: Object,
            default: undefined
        },
        full: {
            tyoe: Boolean,
            default: false,
        }
    },
    emits: [ 'open' ],
    mounted() {
        const parser = new DOMParser();
        let content;
        if ( this.full ) {
            const htmlDoc = parser.parseFromString( this.article.content, "text/html" );
            content = this.$refs.content;
            console.log(htmlDoc.querySelector("body").children);
            for (const element of htmlDoc.querySelector("body").children) {
                element.classList.add("media-section");
                content.appendChild( element.cloneNode(true) );
            }
        } else {
            if (this.article.abstract) {
                const htmlDoc = parser.parseFromString( this.article.abstract, "text/html" );
                content = this.$refs.content;
                for (const element of htmlDoc.querySelector("body").children) {
                    content.appendChild( element.cloneNode(true) );
                }
            } else {
                const htmlDoc = parser.parseFromString( this.article.content, "text/html" );
                content = this.$refs.content;
                let len = 0;
                for (const element of htmlDoc.querySelector("body").children) {
                    content.appendChild( element.cloneNode(true) );
                    len += element.innerHTML.length;
                    if (len > 400) break;
                }
            }
            const openTag = document.createElement("p");
            openTag.innerHTML = "далее...";
            openTag.classList.add("open-article");
            openTag.onclick = () => this.open();
            content.appendChild( openTag );
        }        
    },
    data() {
        return {
        }
    },
    methods: {
        date() {
            if (this.article?.date) return moment(this.article.date).format('DD.MM.YYYY');
        },
        open() {
            this.$emit('open', this.article);
        },
        save() {
            navigator.clipboard.writeText(window.location.href + "?news=" + this.article._id);
        }
    }
}
</script>

<style scoped>

.media-article {
    display: flex;
    flex-direction: column;
}

.cover {
}

.cover-full {
    padding-left: 50px;
    margin-bottom: 20px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    float: right;
}

.cover-mini {
    display: flex;
    justify-content: center;
    float: left;
    max-width: 300px;
    margin-bottom: 20px;
    padding-right: 30px;
}

.cover img {
    max-width: 100%;
    box-shadow: 10px 10px 10px rgba(0,0,0,0.2);
}

.media-article h1 {
    padding-bottom: 20px;
    line-height: 1.2em;
}

.media-content {
    width: auto;
    float: right;
    text-align: justify;
    padding-top: 30px;
}

h3 {
    margin-top: 1em;
}
</style>

<style> 
.media-content ul {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 2em;
}

.media-content ul li {
    padding-bottom: 5px;
}

.open-article {
    cursor: pointer;
    font-style: italic;
    margin-top: 20px;
}

.open-article:hover {
    text-decoration: underline;
}

p.media-section {
    line-height: 1.5em;
    margin-top: 1em;
}

.icon {
    background-size: 32px 32px;
    background-repeat: no-repeat;    
    width: 32px;
    height: 32px;
    padding: 5px;
    background-position: center;
    cursor: pointer;
    border-radius: 5px;
    display: inline-block;
}

.icon.link {
    background-image: url('../public/share.svg');
}

.title {
    font-size: xx-large;
    cursor: pointer;
}

.title .icon {
    opacity: 0%;
    background-position: bottom;
}

.title:hover .icon {
    opacity: 20%;
}

.title:hover .icon:hover {
    opacity: 40%;
}

</style>