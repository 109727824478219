<template>
<Header @change="setPage" :items="pages" ref="header"></Header>
<div v-if="article">
  <Head>
    <template v-slot:header>
      <div class="head-text">
        <h2>{{ article.title }}</h2>
      </div>      
    </template>
  </Head>
  <div class="content">
    <Section v-if="article.type == 'slideshow'">
      <div style="display: flex; flex-direction: row; justify-content: center;">
        <Splide v-if="article.pages" :options="{ rewind: true }" style="width: 85%; border: solid 1px rgba(0,0,0,0.1); box-shadow: 10px 5px 5px rgba(0,0,0,0.3);" :autoWidth="true">
            <SplideSlide v-for="page in article.pages">
                <img :src="page.src" style="width: 100%">
            </SplideSlide>
        </Splide>
      </div>
    </Section>
    <Section v-else>
      <MediaArticle :article="article" :full="true"></MediaArticle>
    </Section>
  </div>
</div>
<div v-if="person">
  <Person2 :person="person" :photo="person.photo2"></Person2>
</div>
<div v-if="job">
  <Job :job="job"></Job>
</div>

<div v-if="page">
  <Head>
    <template v-slot:header>
      <div class="head-text">
        <h2>{{ page.title }}</h2>
      </div>      
    </template>
  </Head>
  <div class="content">
    <Section :code="page.code">
      <div class="page about-page" v-if="page.code == 'about'">
        <p>
          <b>Центр НТИ «Цифровое материаловедение: новые материалы и вещества»</b> - структурное подразделение МГТУ им. Н.Э. Баумана, созданное 28 декабря 2020 года для реализации цифрового подхода к «быстрому» и «сквозному» проектированию, разработке, испытанию и применению новых материалов и веществ. Центр НТИ формирует национальный банк данных и знаний по материалам и их «цифровым двойникам», обеспечивающий получение «цифровых паспортов» и ускоренную сертификацию новых материалов. 
        </p>
        <div v-for="article in presentations">
          <Section v-if="article.type == 'slideshow'">
            <div style="display: flex; flex-direction: row; justify-content: center;">
              <Splide v-if="article.pages" :options="{ rewind: true }" style="width: 85%; border: solid 1px rgba(0,0,0,0.1); box-shadow: 10px 5px 5px rgba(0,0,0,0.3);" :autoWidth="true">
                  <SplideSlide v-for="page in article.pages">
                      <img :src="page.src" style="width: 100%">
                  </SplideSlide>
              </Splide>
            </div>
          </Section>
          <Section v-else>
            <div class="article" v-html="article.content"></div>
          </Section>
        </div>        
      </div>
      <div class="page news-page" v-if="page.code == 'news'">
        <div class="article" v-for="a in news.sort( (a,b) => b.date - a.date )" v-bind="a.id">
          <MediaArticle :article="a" @open="a => showNews(a)"></MediaArticle>
        </div>
      </div>
      <div class="page news-page" v-if="page.code == 'media'">
        <div class="article" v-for="a in media.sort( (a,b) => b.date - a.date )" v-bind="a.id">
          <div class="media-article-title">{{ a.source }}: {{ a.title }}</div>
          <div class="media-article-href"><a :href="a.href">{{ a.href }}</a></div>
        </div>
      </div>
      <div class="page team-page"  v-if="page.code == 'team'">
        <Team :team="team" @choose="setPerson"></Team>
      </div>
      <div class="page reports-page"  v-if="page.code == 'projects'">
        <div class="project-groups">
          <Project v-for="group in project_groups.filter( e => (e.closed === undefined) || !e.closed )" :group="group"></Project>
        </div>
      </div>
      <div class="page reports-page"  v-if="page.code == 'reports'">
      </div>
      <div class="page job-page"  v-if="page.code == 'job'">
        <div v-for="job in openjob">
          <div class="job" @click="showJob(job)">{{ job.title }}</div>
        </div>
        <div style="margin-top: 10px">
          Резюме направлять на почту: <a href="mailto:nti@bmstu.ru">nti@bmstu.ru</a> - с пометкой «Вакансия»
        </div>
      </div>
      <div class="page contacts-page"  v-if="page.code == 'contacts'">
        <div class="contacts-section">
          <h2>По общим вопросам</h2>
          <p>Тел.: +7 (499) 263 69 86</p>
          <p>e-mail: nti@bmstu.ru</p>
        </div>

        <div class="contacts-section">
          <h2>По вопросам проведения исследований, испытаний, научного партнерства</h2>
          <p>Тел.: +7 (916) 075 89 39</p>
          <p>e-mail: pavlovaa@bmstu.ru</p>
          <p>Александр Павлов</p>
        </div>
        
        <div class="contacts-section">
          <h2>По вопросам PR-проектов и запросы от СМИ</h2>
          <p>Тел.: +7 (915) 179 50 26</p>
          <p>e-mail: kad@bmstu.ru</p>
          <p>Анастасия Красовская</p>
        </div>
      </div>
    </Section>
  </div>
</div>
<div v-if="main" id="main">
  <Head>
    <template v-slot:header>
      <div class="head-text">
        Реализует цифровой подход к «быстрому» и «сквозному» проектированию, разработке, испытанию и применению новых материалов и веществ
      </div>      
    </template>
  </Head>
  <div class="content">
    <Section :code="'about'" :title="'О нас'">   
      <div class="about">
        <p>
          <b>Центр НТИ «Цифровое материаловедение: новые материалы и вещества»</b> - структурное подразделение МГТУ им. Н.Э. Баумана, созданное 28 декабря 2020 года для реализации цифрового подхода к «быстрому» и «сквозному» проектированию, разработке, испытанию и применению новых материалов и веществ. Центр НТИ формирует национальный банк данных и знаний по материалам и их «цифровым двойникам», обеспечивающий получение «цифровых паспортов» и ускоренную сертификацию новых материалов. 
        </p>
      </div>
    </Section>
    <Section :code="'news'" :title="'Новости'">
      <div class="news">
        <!--
        <div class="news-item" v-for='n in news.filter( x => x.pinned )' @click="showNews(n)"  style="margin-bottom: 15px">
          <img :src="n.cover" width="100%" />
        </div>
        -->
        <Splide :options="{ rewind: true, pagination: false, perPage: 3, perMove: 1, gap: '15px' }" :autoWidth="true" style="grid-column: span 3; width: 100%;">
          <SplideSlide v-for="n in news.filter( x => x.pinned === undefined || !x.pinned ).sort( (a,b) => { return (a.date < b.date) ? 1 : -1; } )">
            <div class="news-item" @click="showNews(n)" style="margin-bottom: 15px">
              <img :src="n.cover" style="width: 100%" />              
            </div>
            <div class="news-item-label">
              <div>
                {{ n.title }}
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>       
    </Section>
    <Section :title="'Проекты'">
      <div class="project-groups">
        <Project v-for="group in project_groups.filter( e => (e.closed === undefined) || !e.closed )" :group="{title: group.title}">
          <div v-html="group.abstract"></div>
          <div style="font-style: italic; margin-top: 20px; cursor: pointer; font-size: smaller;" @click="setPage('projects')">Посмотреть научные разработки...</div>
        </Project>
      </div>
    </Section>
    <Section :title="'Партнёры'" :code="'partnership'">
      <div v-for="(tp, i) in ['industry','science']" :style="{ 'padding-top': i != 0 ? '70px' : '0px' }">
        <Splide :options="{ rewind: true, pagination: false, perPage: 6, perMove: 1, gap: '15px' }" :autoWidth="true" style="width: 100%;">
          <SplideSlide v-for="p in partners.filter( e => e.type == tp )">
            <div class="partner-logo" style="height: 170px">
              <div class="partner-image">
                <img :src="p.src" />
              </div>
              <div class="partner-title">{{ p.title }}</div>
            </div>
          </SplideSlide>
        </Splide>
      </div>
        <!--
      <div class="logos" v-for="(tp, i) in ['industry','science']" :style="{ 'padding-top': i != 0 ? '70px' : '0px' }">
        <div v-for="p in partners.filter( e => e.type == tp )" class="partner-logo">
          <div class="partner-image">
            <img :src="p.src" />
          </div>          
          <div class="partner-title">{{ p.title }}</div>
        </div>
      </div>
        -->
      
    </Section>
  </div>
</div>
<Footer></Footer>
</template>

<script>

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

import moment from 'moment';

import Header from './Header.vue'
import Head from './Head.vue'
import Section from './Section.vue'
import Team from './Team.vue'
import Project from './Project.vue'
import Footer from './Footer.vue'
import Person from './Person.vue'
import Person2 from './Person2.vue'
import Job from './Job.vue'
import MediaArticle from './MediaArticle'

export default {
  components: {
    Header, Head, Footer, Section, Team, Project, Person, Person2, Job, MediaArticle,
    Splide, SplideSlide,
  }, 
  mounted() {
    window.addEventListener('popstate', (event) => {
      this.clear();
    });
    
    const urlParams = new URLSearchParams(window.location.search);
    //const HOST = "http://localhost:8123";
    const HOST = "";

    fetch(HOST + '/data/news').then( response => response.json() ).then( data => {
      for ( const n of data ) {
        n.date = moment(n.date);
      }
      this.news = data;

      const art = urlParams.get('news');
      if (art) {
        for ( let a of this.news ) {
          if ( a._id == art ) {
            this.showNews(a);
            break;
          }
        }      
      }
    } );
    fetch(HOST + '/data/media').then( response => response.json() ).then( data => {
      this.media = data;
    } );
    fetch(HOST + '/data/vacancies').then( response => response.json() ).then( data => {
      this.openjob = data;
    } );
    fetch(HOST + '/data/team').then( response => response.json() ).then( data => {
      this.team = data;
    } );
  }, 
  created() {
  },
  data() {
    return {
      main: true,
      person: undefined,
      job: undefined,
      article: undefined,
      page: undefined,
      pages: [ 
          {
              title: "Главная",
              items: [
                { title: "О нас", code: "about" },
                { title: "Команда", code: "team" },
              ],
              code: "main",
          },
          {
              title: "Проекты",
              code: "projects",
          },
          {
              title: "Медиа",
              items: [
                { title: "Новости", code: "news" },
                { title: "Публикации в СМИ", code: "media" },
              ],
              code: "news",
          },          
          {
              title: "Вакансии",
              code: "job",
          },
          /*
          {
              title: "Реализованные проекты",
              code: "results",
          },
          */
          {
              title: "Контакты",
              code: "contacts",
          },
      ],
      current_page: 'main',
      team: [ ],
      project_groups: [
        {
          title: "Приоритет-2030",
          abstract: `
<p>Программа развития университетов с целью повышения потенциала университетов и научных организаций.</p>
<p>Программа развития Центра НТИ «Цифровое материаловедение: новые материалы и вещества» МГТУ им. Н.Э. Баумана реализуются по треку «Исследовательское лидерство», ключевыми задачами которого являются  подготовка кадров для сектора исследований и разработок и участие в мировой научной повестке.</p>`,
          projects: [
            "Разработка «умных» суперконструкционных материалов, их цифровых паспортов и технологий создания углепластиков на их основе",
            "Биоразлагаемые композитные материалы для умной пищевой упаковки",
            "Ультразвуковой контроль тонкостенных изделий, выполненных из полимерных композиционных материалов",
          ],
          closed: true,
        },
        {
          title: "Приоритет-2030",
          abstract: `
<p>Программа развития университетов с целью повышения потенциала университетов и научных организаций.</p>
<p>Центр НТИ «Цифровое материаловедение: новые материалы и вещества» МГТУ им. Н.Э. Баумана реализуются по треку «Исследовательское лидерство», ключевыми задачами которого являются  подготовка кадров для сектора исследований и разработок и участие в мировой научной повестке.</p>`,
          projects: [
            "Градиентные полимерные материалы",
            "Полимерные электрооптические материалы для фотонных интегральных схем",
            "Разработка новых термопластичных полиэфиримидов и сополиимидов и исследование их свойств",
          ],
        },
        {
          title: "Дорожная карта высокотехнологичного направления  «Новые материалы и цифровое материаловедение»",
          abstract: `
          <p>«Дорожная карта» позволит обеспечить технологический суверенитет ключевых отраслей экономики России по достижению практически значимых научно-технических результатов мирового и опережающего уровня в области создания новых материалов.</p>
<p>В рамках дорожной карты Центр НТИ «Цифровое материаловедение: новые материалы и вещества» МГТУ им. Н.Э. Баумана реализует проекты поднаправления №4 «Перспективные материалы и цифровое материаловедение».</p>
`,
          projects: [
            "Новые функционально-ориентированные 2D и 3D ПКМ, в том числе с использованием графена и его аналогов, и методов их получения с использованием аддитивных технологий",
            "Разработка цифровой базы данных по полимерным композиционным материалам (ПКМ) в рамках общей цифровой платформы с инженерным и научным ПО",
            "Новые конструкционные термопластичные материалы: направленный дизайн, цифровое моделирование, исследование свойств, технологии производства",
            "Цифровизированный анализ и управление электрохимическими и защитными свойствами специальных функциональных композиционных материалов и «умных» полимерных покрытий для техногенно-природных сред",
            "Разработка единой цифровой технологической платформы инженерного программного обеспечения (в части PDM/CAD/CAE/CAPP/CAM) для проектирования, анализа и автоматизированного изготовления изделий из полимерных композиционных материалов",
            "Разработка единой цифровой платформы по строительному материаловедению с применением инструментов искусственного интеллекта и возможностью моделировать свойства строительных конструкций",
            "Отечественные мембраны на основе ароматических полиамидоимидов для разделения метанол-содержащих смесей жидкостей при производстве высокооктановых присадок к топливу",
            "Разработка пленочных нанокомпозиционных материалов для защиты металлопродукции от коррозии",
            "Аморфные металлические сплавы",
            "Программно-аппаратные средства комплексного проектирования изделий, синтезируемых из металлических порошковых материалов в процессе лазерного аддитивного производства",
            "Разработка подходов к дизайну функциональных материалов на основе графовых нейронных сетей",
            "Цифровое материаловедение под управлением искусственного интеллекта: разработка рентгеновских резистов для элементной базы наноэлектроники нового поколения",
          ]
        },
        {
          title: "«Базовая часть» программы Центра НТИ",
          abstract: `
          <p>Позволяет создавать инновационные решения в области «сквозных» технологий, обеспечивающих глобальное лидерство.</p>
<p>Центр НТИ «Цифровое материаловедение: новые материалы и вещества» МГТУ им. Н.Э. Баумана ежегодно создает охраняемые результаты интеллектуальной деятельности, лицензированные технологии, новые материалы, тем самым вносит непосредственный вклад в развитие экономики страны и ее научно-технологического потенциала.</p>`,
          projects: [
            "Разработка базы данных свойств материалов, полученных по технологии селективного лазерного плавления",
            "Разработка системы неразрушающего контроля качества изделий, выполненных из полимерных композиционных материалов, в том числе с использованием аддитивных технологий",
            "Разработка системы автоматизированного принятия решений по оптимальному выбору композиционных материалов под заданные условия",
          ]
        },
        
      ],
      partners: [
        { title: 'ООО «РЭК»', type: 'industry', href: 'https://rec3d.ru/', src: "/bmstu/logo/rec-logo.webp" },
        { title: 'Фонд НИР «Иннопрактика»', type: 'industry', href: 'https://innopraktika.ru/', src: "/bmstu/logo/Иннопрактика.jpeg" },
        { title: 'ООО «Гален»', type: 'industry', href: null, src: "/bmstu/logo/Гален-logo.jpg" },
        { title: 'ООО «РОУТЕК»', type: 'industry', href: 'https://routek.tech/', src: "/bmstu/logo/routek-logo-white.svg" },
        { title: 'ООО «Синергетик»', type: 'industry', href: 'https://synergetic.ru/', src: "/bmstu/logo/synergetic-logo.svg" },
        { title: 'ООО «ЦВТ»', type: 'industry', href: 'https://chteh.tilda.ws/cvt', src: "/bmstu/logo/ЦВТ-logo.png" },
        { title: 'ООО «Орлан Диджитал»', type: 'industry', href: 'https://orlanm.ru/', src: "/bmstu/logo/orlanm.png" },
        { title: 'ООО НПО «СпецПолимер»', type: 'industry', href: 'https://www.spolymer.pro/', src: "/bmstu/logo/spolymer.pro.png" },
        { title: 'АО «АэроКомпозит»', type: 'industry', href: 'https://uacrussia.ru', src: "/bmstu/logo/АэроКомпозит.jpeg" },
        
        { title: 'МГУ им. М.В. Ломоносова', type: 'science', href: '', src: "/bmstu/logo/МГУ.jpeg" },
        { title: 'НИЯУ МИФИ', type: 'science', href: '', src: "/bmstu/logo/МИФИ.jpeg" },
        { title: 'РФЯЦ-ВНИИТФ', type: 'science', href: '', src: "/bmstu/logo/РФЯЦ-ВНИИТФ.jpeg" },
        { title: 'МЭИ.jpeg', type: 'science', href: '', src: "/bmstu/logo/МЭИ.jpeg" },
        { title: 'ФИЦ ПХФ и МХ РАН', type: 'science', href: '', src: "/bmstu/logo/975189c4-6068-4ffa-8c09-400028276db8.jpeg" },
        { title: 'ИБХФ РАН', type: 'science', href: '', src: "/bmstu/logo/ИБХФ_РАН.jpeg" },
        { title: 'САФУ', type: 'science', href: '', src: "/bmstu/logo/6e45b9d3-de31-4e90-b95e-85a318c20953.jpeg" },
        { title: 'ЮФУ', type: 'science', href: '', src: "/bmstu/logo/16d9c33a-f873-415d-baee-03f5a9bf51c3.jpeg" },        
        { title: 'ДВФУ', type: 'science', href: '', src: "/bmstu/logo/ДВФУ.jpeg" },
        { title: 'РФЯЦ ВНИИЭФ', type: 'science', href: '', src: "/bmstu/logo/ВНИИЭФ.jpeg" },
        { title: 'ИВС РАН', type: 'science', href: '', src: "/bmstu/logo/ИВС.jpeg" },
        { title: 'ИФХЭ РАН', type: 'science', href: '', src: "/bmstu/logo/ИФХЭ.jpeg" },
      ],
      openjob: [ ],
      presentations: [
        {
          title: 'Брошюра центра НТИ',
          type: 'slideshow',
          pinned: true,
          cover: '/img/Брошюра центра НТИ.png',
          pages: [
            { src: '/img/NtiPres2023/page_0000.jpg' },
            { src: '/img/NtiPres2023/page_0001.jpg' },
            { src: '/img/NtiPres2023/page_0002.jpg' },
            { src: '/img/NtiPres2023/page_0003.jpg' },
            { src: '/img/NtiPres2023/page_0004.jpg' },
            { src: '/img/NtiPres2023/page_0005.jpg' },
            { src: '/img/NtiPres2023/page_0006.jpg' },
            { src: '/img/NtiPres2023/page_0007.jpg' },
            { src: '/img/NtiPres2023/page_0008.jpg' },
            { src: '/img/NtiPres2023/page_0009.jpg' },
            { src: '/img/NtiPres2023/page_0010.jpg' },
            { src: '/img/NtiPres2023/page_0011.jpg' },
            { src: '/img/NtiPres2023/page_0012.jpg' },
          ]
        },
      ],
      news: [],
      media: [],
    }
  },
  methods: {
    setPage(page) {
      console.log(page);
      history.pushState({ state: 1 }, '');
      this.main = false;
      this.page = false;
      this.person = undefined;
      this.job    = undefined;
      this.article = undefined;
      this.current_page = page;
      if (page) {
        switch (page) {
          case 'about': {
            this.page = {
              code: 'about',
              title: "О нас",
            };
          } break;
          case 'news': {
            this.page = {
              code: 'news',
              title: "Новости",
            };
          } break;
          case 'team': {
            this.page = {
              code: 'team',
              title: "Команда",
            };
          } break;
          case 'projects': {
            this.page = {
              code: 'projects',
              title: "Проекты",
            };
          } break;
          case 'media': {
            this.page = {
              code: 'media',
              title: "Публикации в СМИ",
            };
          } break;
          case 'presentations': {
            this.page = {
              code: 'presentations',
              title: "Презентационные материалы",
            };
          } break;
          case 'job': {
            this.page = {
              code: 'job',
              title: "Вакансии",
            };
          } break;
          case 'contacts': {
            this.page = {
              code: 'contacts',
              title: "Контакты",
            };
          } break;
          default: {
          }
        }
        const target = document.querySelector("#" + page);
        if (target) {
          target.scrollIntoView({
              behavior: 'smooth'
          });
        } else {
          const observer = new MutationObserver(mutations => {
              const target = document.querySelector("#" + page);
              if (target) {
                  observer.disconnect();
                  target.scrollIntoView({
                      behavior: 'smooth'
                  });
              }
          });
          observer.observe(document.body, {
              childList: true,
              subtree: true
          });
        }
      }      
    },
    clear() {
      this.job = undefined;
      this.article = undefined;
      this.person = undefined;
      this.page = undefined;
      this.history = false;
      this.main = true;
      this.$refs['header'].setItem(undefined);
    },
    setPerson(person) {
      this.clear();
      history.pushState({ state: 1 }, '');      
      this.main = false;
      this.person = person;
      window.scrollTo(0, 0);      
    },
    showJob(job) {
      this.clear();
      history.pushState({ state: 1 }, '');
      this.main = false;
      this.job = job;
      window.scrollTo(0, 0);
    },
    showNews(n) {
      this.clear();
      history.pushState({ state: 1 }, '');
      this.main = false;
      this.article = n;
      window.scrollTo(0, 0);
    }
  },
}
</script>


<style>
@font-face {
  font-family: "ALS_Sector";
  src: url(../public/fonts/ALS_Sector-Regular.woff) format('woff');;
}

* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: "ALS_Sector", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content > div {
  max-width: 1200px;
  padding: 20px;
}

@media screen and (max-width: 1200px) {
  .content > div {
    max-width: 1000px;
    padding: 20px;
  }
}

@media screen and (max-width: 900px) {
  .content > div {
    max-width: 700px;
    padding: 20px;
  }
}

@media screen and (max-width: 600px) {
  .content > div {
    max-width: 500px;
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .content > div {
    max-width: 340px;
  }
}

</style>

<style scoped>

.about {
  padding-bottom: 50px;
  text-align: left;
  line-height: 1.5em;
}
.about p {
  padding-top: 10px;
}

.about ul {
  padding-left: 20px;
}

.org-logo {
  width: 200px;
}

.org-logo > img {
  width: 200px;
}

.carousel__item {
  min-height: 200px;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item > img {
  width: 200px;
}

.carousel__item_content {
  width: 200px; 
  height: 200px; 
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  justify-content: center; 
  background-color: white; 
  border: solid 1px black;
}

@media screen and (max-width: 1200px) {
  .carousel__item {
    min-height: 150px;
  }

  .carousel__item > img {
    width: 150px;
  }

  .carousel__item_content {
    width: 150px; 
    height: 150px; 
  }
}

.carousel__slide {
  padding: 2px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
}

.carousel__viewport {
  width: 90%;
  margin-left: 5%;
}

.organize li {
  list-style-position: inside;
  list-style: none;
  padding-top: 5px;
  text-indent: -.8em;
  padding-left: 1em;
}

.organize li::before {
  content: " ● ";
  color: #0bb0b6;
}

.partner-logo {
  min-height: 150px;
  border: solid 1px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.partner-image {
  flex: 1;
  display: flex;
  align-items: center;
}

.partner-title {
  flex: 0;
  text-align: center;
  font-size: smaller;
}

.partner-image > img {
  width: 100%;
}

.logos {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 5px;
  row-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.head-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
    font-size: larger;
}

.news {
}

.news-item {
  border: solid 1px rgba(0,0,0,0.1);
  box-shadow: 10px 5px 5px rgba(0,0,0,0.3);
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.news-item-label {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.news-item-label > div {
  background-color: rgba(0,0,0,.35);
  color: white;
  width: 100%;
  margin-bottom: 15px;
  backdrop-filter: blur(15px);
  height: 70px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: smaller;
}

.news-item > img {
  width: 100%;
}

.article {
  line-height: 1.5em;
}

.article > p {
  margin-top: 20px;
}

.article li {
  margin-top: 10px;
  margin-left: 15px;
}

</style>

<style scoped>
.head-text {
    padding-top: 50px;
    width: 600px;
    text-align: center;
}

.job {
  font-size: larger;
  padding: 5px;
}

.job::before {
  content: "• "
}

.job:hover {
  cursor: pointer;
}

.project-groups p {
  padding: 10px;
}

.project-groups li {
  margin-left: 20px;
}

.project-title {
  font-size:larger;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.project-title:hover {
  text-decoration: underline;
}

.contacts-section {
  margin-top: 20px;
}

.article {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: solid 1px rgba(0,0,0,0.1);
}
</style>